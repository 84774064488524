<template>
  <div>
    <div class="contactUsContent">
      <h1>Contact us</h1>
      <h3>Get in touch and let us know how we can help.</h3>
      <div class="contactUsInformationBoxes">
        <div class="contactUsInformationBox">
          <img class="email" src="@/assets/img/email.svg" />
          <div class="mailContainer">
            <a href="mailto:hello@tjing.se" class="internalLink">hello@tjing.se</a>
          </div>
        </div>
        <div class="contactUsInformationBox">
          <img class="phone" src="@/assets/img/phone.svg" />
          <p>+46 762 689 225</p>
        </div>
        <div class="contactUsInformationBox contactUsInformationChat">
          <img class="chat" src="@/assets/img/chat.svg" />
          <p>Chat</p>
          <div class="comingSoon">
            <p>Coming soon</p>
          </div>
        </div>
      </div>
    </div>
    <SignUpCTA />
  </div>
</template>

<script>
import SignUpCTA from "@/components/SignUpCTA.vue";
export default {
  name: "home",
  components: {
    SignUpCTA,
  },
};
</script>
<style lang="scss" scoped>
@import "../styles/global-styles.scss";

.contactUsContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 1200px;
  max-width: 80%;
  margin: 0 auto 60px auto;
  text-align: center;
  h1 {
    font-size: 44px;
    @include Gilroy-Bold;
  }
  h3 {
    font-size: 22px;
    line-height: 28px;
    @include Gilroy-Regular;
  }
  .contactUsInformationBoxes {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 40px;
    margin-top: 26px;
    margin-bottom: 100px;
    .contactUsInformationBox {
      position: relative;
      background: #f5f5f6;
      display: flex;
      flex-direction: column;
      justify-content: center;
      border-radius: 10px;
      width: 288px;
      height: 220px;
      img {
        &.email {
          height: 44px;
        }
        &.phone {
          height: 60px;
        }
        &.chat {
          height: 59px;
        }
      }
      p,
      .mailContainer {
        margin-top: 42px;
        margin-bottom: 0px;
        font-size: 24px;
        line-height: 40px;
      }
      .mailContainer {
        display: flex;
        justify-content: center;
        a {
          color: #212529;
          &:hover {
            color: #212529 !important;
          }
          &:after {
            background: #212529 !important;
          }
        }
      }
      &.contactUsInformationChat {
        .comingSoon {
          position: absolute;
          bottom: 8px;
          width: 100%;
          p {
            font-size: 14px;
            @include Gilroy-SemiBold;
            color: #7d8296;
          }
        }
      }
    }
  }
}

@media (min-width: 480px) and (orientation: landscape) {
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
  .contactUsContent {
    h1 {
      font-size: 64px;
      line-height: 84px;
    }
    h3 {
      font-size: 32px;
      line-height: 48px;
    }
    .contactUsInformationBoxes {
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 20px;
      margin-top: 60px;
      .contactUsInformationBox {
        width: 232px;
        height: 180px;
        p,
        .mailContainer {
          font-size: 18px;
          margin-top: 22px;
        }
      }
    }
  }
}
@media (min-width: 992px) {
}
@media (min-width: 1200px) {
  .contactUsContent {
    .contactUsInformationBoxes {
      grid-gap: 100px;
      .contactUsInformationBox {
        width: 288px;
        height: 220px;
        p {
          font-size: 24px;
        }
      }
    }
  }
}
</style>
